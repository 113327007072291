import firebase from 'firebase/app';
import 'firebase/auth';

// move to env (not security concern)
const config = Object.freeze({
  apiKey: 'AIzaSyDpUh9CSkhT1ne-J0hl7sw_SzqHTLYLFVE',
  authDomain: 'brawnzaa-m1309e1295.firebaseapp.com',
  databaseURL: 'https://brawnzaa-m1309e1295.firebaseio.com',
  projectId: 'brawnzaa-m1309e1295',
  storageBucket: 'brawnzaa-m1309e1295.appspot.com',
  messagingSenderId: '741163391047',
  appId: '1:741163391047:web:76577d5a9e0e4016d17962',
  measurementId: 'G-6NQ7148NL0',
});

firebase.initializeApp(config);

window.firebase = firebase; // REMOVE

export { firebase };
